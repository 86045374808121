import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Parallax } from "react-parallax"
import aboutBackground from "../../static/images/xeneco_mediaintegrator_about.jpg"

const DivWithBg = styled.div`
  /* background: rgba(0,0,0,0.2); */
  color: #fff;
  padding: 65px 0;
  /* border-radius: 40px 40px 0 0; */
  /* background-image: url(${aboutBackground});
  background-size: cover;
  background-position: center bottom; */
  text-shadow: 2px 2px 0px #0000004f;
  @media screen and (max-width: 960px) {
    text-shadow: 1px 1px 0px #0000004f;
  }
`

const About = ({ lang }) => (
  <Parallax
    // blur={10}
    bgImage={aboutBackground}
    bgImageAlt="the cat"
    strength={500}
    blur={0}
  >
    <DivWithBg id="about" className={"about-us"}>
      <div className={"container"}>
        {lang === "pl" ? (
          <div className={"about-us__content"}>
            <h2>O nas</h2>
            <p>
              Początkiem powstania Xeneco, było założenie spółki w 2008 roku
              powstałej z pasji, doświadczenia i znajomości rynku mediów OOH
              oraz technologii mapowych. Zbierane doświadczenie oraz
              zaangażowanie pozwoliło zespołowi Xeneco na tworzenie,
              rozbudowywanie i udoskonalanie narzędzi oraz świadczonych usług
              skierowanych do sektora mediów Out of Home.
            </p>
            <p>
              Unikalna wiedza, doświadczenie oraz profesjonalizm zaprocentowały
              stworzeniem platformy Outdoor Media Integrator, która stała się
              standardem wśród narzędzi wspierających pracę w zakresie
              planowania i optymalizacji selekcji outdoorowych kampanii
              reklamowych.
            </p>
            <p>
              Współpracujemy z firmami zewnętrznymi zajmującymi się między
              innymi badaniem rynku, zbieraniem danych, analizami przestrzennymi
              dzięki temu pozostajemy liderem innowacji na rynku OOH rozwijając
              najpopularniejsze narzędzie do planowania kampanii OOH, którym
              jest Outdoor Media Integrator (OMI).
            </p>
          </div>
        ) : (
          <div className={"about-us__content"}>
            <h2>About us</h2>
            <p>
              The beginning of Xeneco was the establishment of the company in
              2008, created out of passion, experience and knowledge of the
              OOHmedia market and map technologies.
            </p>
            <p>
              The accumulated experience and commitment allowed the Xeneco
              teamto create, expand and improve the tools and services provided
              to the media sector Out of Home.
            </p>
            <p>
              Unique knowledge, experience and professionalism resulted in the
              creation of the Outdoor Media Integrator platform, which has
              becomea standard among tools supporting work in the field of
              planning and optimizing the selection of outdoor advertising
              campaigns.
            </p>
            <p>
              We cooperate with external companies like from market research,
              data collection, spatial analysis, thanks to which we remain the
              innovation leader on the OOH market by developing the most popular
              tool for planning OOH campaigns, which is Outdoor Media Integrator
              (OMI).
            </p>
          </div>
        )}
      </div>
    </DivWithBg>
  </Parallax>
)

About.propTypes = {
  siteTitle: PropTypes.string,
  lang: PropTypes.string,
}

About.defaultProps = {
  lang: `pl`,
}

export default About
