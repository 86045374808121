import React from "react"
import PropTypes from "prop-types"

import arena from "../../static/images/clients/arena.png"
import dentsu from "../../static/images/clients/dentsu.png"
import fullsix from "../../static/images/clients/fullsix.png"
import grouponemedia from "../../static/images/clients/grouponemedia.png"
import havas_media from "../../static/images/clients/havas_media.png"
import havas_media_group from "../../static/images/clients/havas_media_group.png"
import mdm from "../../static/images/clients/mdm.png"
import media_direction from "../../static/images/clients/media_direction.png"
import media_plus from "../../static/images/clients/media_plus.png"
import media_republic from "../../static/images/clients/media_republic.png"
import media from "../../static/images/clients/media.png"
import mindshare from "../../static/images/clients/mindshare.png"
import omd from "../../static/images/clients/omd_2.png"
import omnicom_media_group from "../../static/images/clients/omnicom_media_group.png"
import phd from "../../static/images/clients/phd.png"
import publicis_groupe from "../../static/images/clients/publicis_groupe.png"
import sigma_bis from "../../static/images/clients/sigma_bis.png"
import spark from "../../static/images/clients/spark.png"
import starcom from "../../static/images/clients/starcom.png"
import value_media from "../../static/images/clients/value_media.png"
import zenith from "../../static/images/clients/zenith.png"

const Clients = ({ lang }) => (
  <div className={"container"}>
    <div className={"clients"}>
      <h2>{lang === "pl" ? "Zaufali nam" : "They trusted us"}</h2>
      <div className={"clients__column"}>
        <div className={"clients__item"}>
          <img alt={"client logo 10"} src={publicis_groupe} />
        </div>
        <div className={"clients__item clients__item-smaller"}>
          <img alt={"client logo 12"} src={starcom} />
        </div>
        <div className={"clients__item"}>
          <img alt={"client logo 14"} src={zenith} />
        </div>
        <div className={"clients__item"}>
          <img alt={"client logo 17"} src={spark} />
        </div>
      </div>
      <div className={"clients__column"}>
        <div className={"clients__item"}>
          <img alt={"client logo 8"} src={havas_media_group} />
        </div>
        <div className={"clients__item"}>
          <img alt={"client logo 4"} src={havas_media} />
        </div>
        <div className={"clients__item"}>
          <img alt={"client logo 9"} src={arena} />
        </div>
        <div className={"clients__item"}>
          <img alt={"client logo 3"} src={fullsix} />
        </div>
      </div>
      <div className={"clients__column"}>
        <div className={"clients__item"}>
          <img alt={"client logo 15"} src={omnicom_media_group} />
        </div>
        <div className={"clients__item clients__item-smaller"}>
          <img alt={"client logo 6"} src={omd} />
        </div>
        <div className={"clients__item"}>
          <img alt={"client logo 11"} src={phd} />
        </div>
        <div className={"clients__item"}>
          <img alt={"client logo 5"} src={mdm} />
        </div>
        <div className={"clients__item"}>
          <img alt={"client logo 7"} src={media_direction} />
        </div>
      </div>
      <div className={"clients__column"}>
        <div className={"clients__item"}>
          <img alt={"client logo 13"} src={grouponemedia} />
        </div>
        <div className={"clients__item"}>
          <img alt={"client logo 1"} src={value_media} />
        </div>
        <div className={"clients__item"}>
          <img alt={"client logo 1"} src={media_plus} />
        </div>
        <div className={"clients__item"}>
          <img alt={"client logo 1"} src={media_republic} />
        </div>
        <div className={"clients__item"}>
          <img alt={"client logo 1"} src={media} />
        </div>
      </div>
      <div className={"clients__column"}>
        <div className={"clients__item clients__item-bigger"}>
          <img alt={"client logo 18"} src={dentsu} />
        </div>
        <div className={"clients__item"}>
          <img alt={"client logo 2"} src={sigma_bis} />
        </div>
        <div className={"clients__item"}>
          <img alt={"client logo 16"} src={mindshare} />
        </div>
      </div>
    </div>
  </div>
)

Clients.propTypes = {
  lang: PropTypes.string,
}

Clients.defaultProps = {
  lang: `pl`,
}

export default Clients
